import alertify from "alertifyjs";
import React from "react";

export const renderHTML = (rawHTML) => React.createElement("div",
    { dangerouslySetInnerHTML: { __html: rawHTML } });

export const toUrlPath = (name) => {
    if (!name) return "";
    return name.replace(" ", "-").toLowerCase();
}

export const downloadFile = (blob, filename) => {
    var a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.click();
};


export const notifySaveSuccess = () => {
    alertify.success("Successfully saved");
}

export const notifyInfo = (msg) => {
    alertify.success(msg);
}

export const notifyMsg = (msg) => {
    alertify.message(msg);
}

export const alertError = (msg, title = "Error") => {
    alertify.alert(title, msg.toString().replace("\r\n", "<br>")).set('label', 'OK');
}

export const parseJson = (str) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return false;
    }
}

export const handleResponse = (apixResponse, onSuccess, successMessage, failedMessage, onFailed) => {
    if (apixResponse.ok) {
        if (successMessage) notifyInfo(successMessage)
        if (onSuccess) onSuccess(apixResponse.data)
    } else {
        var msg = apixResponse.data;
        if (apixResponse.code == 403)
            alertError("Access denied. You are not authorized to access.")
        else {
            try {
                const json = parseJson(apixResponse.data)
                if (json && json.message)
                    msg = json.message;
            } catch (e) {
            }
            if (!onFailed) alertError((failedMessage ?? "") + " " + msg)
        }
        if (onFailed) onFailed(msg)
    }
}

// // intended to replace handleResonse
// export const handleResponseX = (apixResponse,
//     onSuccess = null, onFailed = null, successMessage = "", failedMessage = ""
// ) => {
//     if (apixResponse.ok) {
//         if (successMessage) notifyInfo(successMessage)
//         if (onSuccess) onSuccess(apixResponse.data)
//     } else {
//         var msg = apixResponse.data;
//         if (apixResponse.code == 403)
//             alertError("Access denied. You are not authorized to access.")
//         else {
//             try {
//                 const json = parseJson(apixResponse.data)
//                 if (json && json.message)
//                     msg = json.message;
//             } catch (e) {
//             }
//             if (!onFailed) alertError((failedMessage ?? "") + " " + msg)
//         }
//         if (onFailed) onFailed(msg)
//     }
// }


export const handleResponseX = (
    apixResponse,
    success,
    failed
  ) => {
    if (apixResponse.ok) {
      if(success) {
        if (typeof(success) === "string")
          notifyInfo(success)
        if (typeof(success) === "function")
          success(apixResponse.data)
      }
    } else {
      var msg = apixResponse.data;
      if (apixResponse.code == 403)
        alertError('Access denied. You are not authorized to access.');
      else {
        try {
          const json = parseJson(apixResponse.data);
          if (json && json.message) msg = json.message;
        } catch {}
        // if (!onFailed) alertError((failedMessage ?? '') + ' ' + msg);
        if(failed) {
          if (typeof(failed) === "string")
            alertError(`${failed} ${msg}`)
          if (typeof(failed) === "function")
            failed(msg)
        } 
        else 
          alertError(msg);
      }
    }
  };


export const alertInfo = (msg) => {
    alertify.alert("Info", msg.replace("\r\n", "<br>")).set('label', 'OK');
}
export const alertWarning = (msg) => {
    alertify.alert("Warning", msg.replace("\r\n", "<br>")).set('label', 'OK');
}

export const showAlert = (msgType = "Info", header, messages) => {
    const msg = `${header}: <ul><li>${messages.join("</li><li>")}</li></ul>`
    alertify.alert(msgType, msg.replace("\r\n", "<br>")).set('label', 'OK');
}

export const confirmDialog = (msg = "Are you sure?", onConfirm, onCancel) => {
    alertify.confirm('Confirm', msg,
        function () { if (onConfirm) onConfirm() },
        function () { if (onCancel) onCancel() }
    ).set('labels', { ok: 'Yes', cancel: 'No' });
}

export const genId = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const formatListMessages = (title, msgs) => {
    let msg = "";
    if (title) msg = title + ": <br>";
    if (msgs) {
        const errKeys = Object.keys(msgs)
        msg += `<ul>`
        errKeys.forEach((x) => {
            msg += `<li> ${x}: ${msgs[x]} </li>`
        })
        msg += `</ul>`
    }
    return msg;
}