module.exports = {
  select_language: 'Select Language',
  login_title: 'Login Form',
  login_button: 'Login',
  username: 'User name',
  password: 'Password',
  login_notes: 'Enter your user name and password to login',
  set_password: 'Set password',
  profile: 'Profile',
  signout: 'Signout',
  administration: 'Administration',
  dark_mode: 'Dark mode',
  light_mode: 'Light mode',
  design: 'Design',
  develop: 'Develop',
  apps: 'Apps',
  app: 'App',
  models: 'Models',
  pages: 'Pages',
  workflows: 'Workflows',
  security: 'Security',
  users: 'Users',
  roles: 'Roles',
  phone_number: 'Phone Number',
  total_fields: 'Total fields',
  filter: 'Filter',
  create: 'Create',
  create_new_password: 'Create new password',
  edit: 'Edit',
  close: 'Close',
  save: 'Save',
  update: 'Update',
  total_records: 'Total Records',
  type_to_search: 'Type to search',
  copyright_notes: 'All rights reserved',
  download: 'Download',
  supported_file_types: 'Supported file types',
  max_file_size: 'Max file size',
  drop_zone_intro_message:
    'Please drag files here or click to browse for files',
  full_name: 'Full name',
  email: 'Email',
  phone: 'Phone',
  role_name: 'Role name',
  description: 'Description',
  total_users: 'Total users',
  host_name: 'Host name',
  company_name: 'Company name',
  name: 'Name',
  permissions: 'Permissions',
  total: 'Total',
  field_required: 'Field required',
  min_length: 'Mininum characters',
  max_length: 'Maximum characters',
  invalid_format: 'Invalid format',
  item_name: 'Item name',
  list: 'List',
  view: 'View',
  delete: 'Delete',
  post: 'Post',
  import: 'Import',
  export: 'Export',
  dataadmin: 'Data Admin',
  login_type: 'Login type',
  reset_password: 'Reset Password',
  'forgot-password': 'Forgot Password',
  confirm_delete: 'Are you sure to delete?',
  confirm_release_app: 'Are you sure to release app?',
  yes: 'Yes',
  no: 'No',
  sucessfully_saved: 'Sucessfully saved',
  sucessfully_deleted: 'Sucessfully deleted',
  sucessfully_released: 'Successfully released',
  confirm_delete_record: 'Are you sure to delete this record?',
  confirm_post_record: 'Are you sure to post this record?',
  show: 'Show',
  add_item: 'Add Item',
  page_error_title: 'An error occurred',
  error_code: 'Error code',
  go_back_home: 'Go back home',
  confirm_password: 'Confirm Password',
  id: 'ID',
  code: 'Code',
  submit: 'Submit',
  tenant: 'Tenant',
  tenants: 'Tenants',
  tenant_name: 'Tenant name',
  tenant_code: 'Tenant code',
  registered_date: 'Registered date',
  url: 'URL',
  disabled: 'Disabled',
  login_email: 'Login email',
  enable_row_permission: 'Enable row permission',
  data_access_parties: 'Only these teams can access this data:',
  data_partitions: 'Data Partitions',
  parties: 'Parties',
  party_name: 'Party Name',
  min_value: 'Mininum value',
};
