
// require('dotenv').config()
//console.log(process.env) // remove this after you've confirmed it is working

export const defaultMenuType = 'menu-sub-hidden';
export const MENU_NO_LANDING_PAGE = 'MENU_NO_LANDING_PAGE';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'kh', name: 'ភាសាខ្មែរ', direction: 'ltr' }
];

export const currentUser = {};

// export const adminRoot = '/admin';

export const searchPath = `${""}/pages/miscellaneous/search`;
//export const servicePath = process.env.REACT_APP_API_URL ?? "https://agentapi.iac.org.kh/api";

// export const servicePath = process.env.NODE_ENV == "production" ?
//   "https://agentapi.assurasolutions.com.kh/api"
//   : "http://localhost:9900/api";

  // export const servicePath = process.env.NODE_ENV == "production" ?
  //   "https://agentapi.iac.org.kh/api"
  // : "http://localhost:9900/api";

export const servicePath = process.env.REACT_APP_API_URL || "http://localhost:9900/api";

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.blueyale';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
